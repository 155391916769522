import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { basePropsSDKFactory } from '../../../core/corvid/props-factories';
import {
  IProgressBarSDKFactory,
  IProgressBarProps,
  IProgressBarSDK,
} from '../ProgressBar.types';

const progressBarSDKFactory: IProgressBarSDKFactory = ({
  setProps,
  props,
}) => ({
  get value() {
    return props.value;
  },
  set value(value) {
    setProps({ value });
  },
  get targetValue() {
    return props.targetValue ?? 100;
  },
  set targetValue(value) {
    setProps({ targetValue: value });
  },
});

const progressBar = withValidation(progressBarSDKFactory, {
  type: ['object'],
  properties: {
    value: {
      type: ['number'],
      warnIfNil: true,
      minimum: 0,
      maximum: Number.MAX_SAFE_INTEGER,
    },
    targetValue: {
      type: ['number'],
      warnIfNil: true,
      minimum: 0,
      maximum: Number.MAX_SAFE_INTEGER,
    },
  },
});

export const sdk = composeSDKFactories<IProgressBarProps, IProgressBarSDK>(
  basePropsSDKFactory,
  progressBar,
);
