import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  IHoverBoxSDK,
  IHoverBoxSDKFactory,
  HoverBoxCompProps,
  IHoverBoxOwnSDKFactory,
} from '../HoverBox.types';
import { backgroundPropsSDKFactory } from '../../../../core/corvid/props-factories/backgroundPropsSDKFactory';

import {
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../../core/corvid/props-factories';

const type = '$w.HoverBox';

const hoverBoxSDKFactory: IHoverBoxOwnSDKFactory = ({ metaData }) => {
  return {
    get type() {
      return type;
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        type,
      };
    },
  };
};

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk: IHoverBoxSDKFactory = composeSDKFactories<
  HoverBoxCompProps,
  IHoverBoxSDK
>(
  elementPropsSDKFactory,
  hoverBoxSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  backgroundPropsSDKFactory,
);
