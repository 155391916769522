import {
  withValidation,
  registerCorvidEvent,
} from '@wix/editor-elements-corvid-utils';
import {
  CorvidTypes,
  ICorvidEvent,
  CorvidEvent,
} from '@wix/editor-elements-types';
import { convertGalleryItemsToUserModel } from '../../galleries/GallerySDKUtils';
import {
  ItemUserModel,
  GalleryItemProps,
} from '../../galleries/GalleriesSDK.types';

export interface IGalleryOnItemClickedPropsSDKProps {
  items: Array<GalleryItemProps>;
}

export type OnItemClickedEvent = {
  item: ItemUserModel;
  itemIndex: number;
  type: string;
};

export interface IGalleryOnItemClickedOwnSDKProps {
  onItemClicked(handler: CorvidEvent<OnItemClickedEvent & ICorvidEvent>): void;
}

const _onItemClickSDKFactory: CorvidTypes.CorvidSDKFactory<
  IGalleryOnItemClickedPropsSDKProps,
  IGalleryOnItemClickedOwnSDKProps
> = api => {
  return {
    onItemClicked(handler) {
      registerCorvidEvent<
        OnItemClickedEvent,
        OnItemClickedEvent & ICorvidEvent
      >('onItemClicked', api, handler, ({ componentEvent }) => {
        const convertedItemToUserModel = convertGalleryItemsToUserModel(
          [api.props.items[componentEvent.itemIndex]],
          api.linkUtils,
        )[0];
        return { ...componentEvent, item: convertedItemToUserModel };
      });
    },
  };
};

export const onItemClickSDKFactory = withValidation(_onItemClickSDKFactory, {
  type: ['object'],
  properties: {
    onItemClicked: {
      type: ['function'],
      args: [{ type: ['function'] }],
    },
  },
});
