import { TextInputType } from '@wix/thunderbolt-components-native/src/components/TextInput/types';
import { ITextInputProps } from '../../TextInput.types';
import {
  composeValidators,
  validateRangeOverflow,
  validateStepMismatch,
  validateUrl,
  validateRequired,
  validatePattern,
  validateEmail,
  validateRangeUnderflow,
  ValidationData,
} from '../../../../core/corvid/inputUtils';

const validateTextInputByType: {
  [key in TextInputType]: (props: ITextInputProps) => ValidationData;
} = {
  text: composeValidators<ITextInputProps>([validateRequired, validatePattern]),
  password: composeValidators<ITextInputProps>([
    validateRequired,
    validatePattern,
  ]),
  number: composeValidators<ITextInputProps>([
    validateRequired,
    validateRangeUnderflow,
    validateRangeOverflow,
    validateStepMismatch,
  ]),
  email: composeValidators<ITextInputProps>([
    validateRequired,
    validateEmail,
    validatePattern,
  ]),
  url: composeValidators<ITextInputProps>([
    validateRequired,
    validateUrl,
    validatePattern,
  ]),
  tel: composeValidators<ITextInputProps>([validateRequired, validatePattern]),
  search: composeValidators<ITextInputProps>([
    validateRequired,
    validatePattern,
  ]),
};

export const validateTextInput = (props: ITextInputProps): ValidationData => {
  return validateTextInputByType[props.inputType](props) as ValidationData;
};
