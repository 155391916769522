import {
  composeSDKFactories,
  reportError,
  messages,
} from '@wix/editor-elements-corvid-utils';
import {
  ISignatureInputProps,
  ISignatureInputSDKFactory,
  ISignatureInputSDK,
  ISignatureInputImperativeActions,
  ISignatureInputOwnSDKFactory,
} from '../SignatureInput.types';
import {
  createValidationPropsSDKFactory,
  createRequiredPropsSDKFactory,
  focusPropsSDKFactory,
  disablePropsSDKFactory,
  changePropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
  createStylePropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  createInputValidator,
  InputValidator,
  composeValidators,
  validateRequired,
} from '../../../core/corvid/inputUtils';

const SignatureInputValidator: InputValidator<
  ISignatureInputProps,
  ISignatureInputImperativeActions
> = createInputValidator(
  composeValidators<ISignatureInputProps>([validateRequired]),
);

const validationPropsSDKFactory = createValidationPropsSDKFactory<
  ISignatureInputProps,
  ISignatureInputImperativeActions
>(SignatureInputValidator);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory<
  ISignatureInputProps,
  ISignatureInputImperativeActions
>(SignatureInputValidator);

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
});

const ownSDKFactory: ISignatureInputOwnSDKFactory = api => {
  const { props, compRef, metaData, setProps } = api;

  return {
    get value() {
      return props.value;
    },
    set value(_value) {
      reportError(
        messages.onlyGetter({
          compType: 'SignatureInput',
          propertyName: 'value',
        }),
      );
    },
    clear() {
      if (props.value) {
        compRef.clear();
      } else {
        setProps({
          shouldShowValidityIndication: false,
        });
      }
    },
    toJSON() {
      const { required, value } = props;
      return {
        ...toJSONBase(metaData),
        value,
        required,
      };
    },
  };
};

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk: ISignatureInputSDKFactory = composeSDKFactories<
  ISignatureInputProps,
  ISignatureInputSDK,
  any
>(
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  requiredPropsSDKFactory,
  stylePropsSDKFactory,
  validationPropsSDKFactory,
  changePropsSDKFactory,
  ownSDKFactory,
);
