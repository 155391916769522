import { withValidation } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import { convertGalleryItemsToUserModel } from '../../galleries/GallerySDKUtils';
import {
  GalleryItemProps,
  ItemUserModel,
} from '../../galleries/GalleriesSDK.types';

export type OnCurrentItemChangedEvent = {
  type: string;
  item: ItemUserModel;
  itemIndex: number;
};

export interface ICurrentItemPropsSDKProps {
  items: [GalleryItemProps];
}

export interface ICurrentItemPropsSDK {
  onCurrentItemChanged(
    handler: (event: OnCurrentItemChangedEvent) => void,
  ): void;
}

const _currentItemSDKFactory: CorvidTypes.CorvidSDKFactory<
  ICurrentItemPropsSDKProps,
  ICurrentItemPropsSDK
> = ({ registerEvent, linkUtils, props }) => {
  return {
    onCurrentItemChanged(handler) {
      const items = props.items;
      registerEvent(
        'onCurrentItemChanged',
        (event: OnCurrentItemChangedEvent) => {
          const changedItem = items[event.itemIndex];
          const convertedItemToUserModel = convertGalleryItemsToUserModel(
            [changedItem],
            linkUtils,
          );
          [event.item] = convertedItemToUserModel;
          handler(event);
        },
      );
    },
  };
};

export const currentItemSDKFactory = withValidation(_currentItemSDKFactory, {
  type: ['object'],
  properties: {
    onCurrentItemChanged: {
      type: ['function'],
      args: [{ type: ['function'] }],
    },
  },
});
