import * as vectorImage from '@wix/thunderbolt-commons/dist/vectorImage';
import { VectorImageSdkData } from '@wix/thunderbolt-components';
import { CorvidSDKFactory } from '@wix/editor-elements-types';
import {
  withValidation,
  messages,
  reportError,
  composeSDKFactories,
  createSdkState,
} from '@wix/editor-elements-corvid-utils';
import { VectorImageProps } from '../VectorImage.types';
import {
  clickPropsSDKFactory,
  linkPropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  fetchSvg,
  resolveSvg,
  createSvgWixMediaUrl,
  isFallbackSvg,
  isValidSvgValue,
  SVG_TYPE_INLINE,
} from '../../../core/corvid/svg';

const processSvg = (rawSvg: string, sdkData: ClientSdkData) => {
  const { info: svgInfo } = vectorImage.parseSvgString(rawSvg);
  return vectorImage.transformVectorImage(rawSvg, {
    ...sdkData,
    svgInfo,
  } as vectorImage.VectorImageTransformationOptions);
};

const fetchAndTransformSvg = async (
  userSrc: string,
  sdkData: ClientSdkData,
) => {
  const { type, data } = resolveSvg(userSrc, sdkData.mediaSvgUrl);
  if (type === SVG_TYPE_INLINE) {
    return { svgContent: data };
  }
  const svg = await fetchSvg(data);
  return {
    svgContent: isFallbackSvg(svg) ? svg : processSvg(svg, sdkData),
  };
};

type ClientSdkData = VectorImageSdkData & {
  userSrc?: string;
};

export type VectorImageSDK = {
  src: string;
};

const _vectorImageSDKFactory: CorvidSDKFactory<
  VectorImageProps,
  VectorImageSDK,
  ClientSdkData
> = ({ setProps, sdkData, metaData }) => {
  const [state, setState] = createSdkState<{ userSrc?: string }>({}, metaData);
  return {
    get src() {
      return (
        state.userSrc || createSvgWixMediaUrl(sdkData.svgId, sdkData.altText)
      );
    },
    set src(url) {
      setState({ userSrc: url });
      setProps(fetchAndTransformSvg(url, sdkData));
    },
    toJSON() {
      const { src } = this;
      return {
        ...toJSONBase(metaData),
        src,
      };
    },
  };
};

const vectorImageSDKFactory = withValidation(
  _vectorImageSDKFactory,
  {
    type: ['object'],
    properties: { src: { type: ['string'] } },
  },
  {
    src: [
      (value: VectorImageSDK['src']) => {
        const isValid = isValidSvgValue(value);
        if (!isValid) {
          // FIXME - customRule will eventually need to inject 'index' argment (for repeaters scenario)
          reportError(messages.invalidSvgValue(value));
        }
        return true;
      },
    ],
  },
);

export const sdk = composeSDKFactories<VectorImageProps, VectorImageSDK>(
  createElementPropsSDKFactory(),
  clickPropsSDKFactory,
  linkPropsSDKFactory,
  vectorImageSDKFactory,
);
